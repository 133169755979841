
import { defineComponent, onMounted, ref, watch } from "vue";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import axios, { AxiosError, AxiosResponse } from "axios";
import useAlert from "@/composables/Alert";
import { IStoreData } from "@/views/ProductStore/ListPage.vue";
import { useFilterMainStore } from "@/store/FilterMainStore";
import { useCompanyStore } from '@/store/CompanyStore';
import { useStoreStore } from '@/store/StoreStore';
import { useRemittanceStore } from '@/store/RemittanceStore';
import { useSaleStore } from "@/store/SaleStore";
import { usePostStore } from "@/store/PostStore";
// import { useNotificationStore } from "@/store/NotificationStore";
import { useAuthStore } from "@/store/AuthStore";

type TCompanyData = IStoreData

type TOptionList = null | { id: number | string, label: string, cnpj: string }[]

export default defineComponent({
  name: "FilterMain",
  props: {
    id: {
      type: String,
      required: true
    },
    requiredFields: {
      type: Array,
    },
    fields: {
      type: Array,
    },
    subtitle: {
      type: String
    },
    routeNameToRedirect: {
      type: String
    },
    goToBackBtnDesc: {
      type: String
    },
    allCompany: {
      type: Boolean,
      default: true
    },
    allStore: {
      type: Boolean
    }
  },
  components: {
    FilterBase,
    ElConfigProvider,
  },
  setup(props, { emit }) {
    const { showTimeAlert } = useAlert()
    const activeModal = ref(false)
    const closeModal = ref(false)
    const isLoading = ref(true)
    const filterMainStore = useFilterMainStore()
    const isWarning = ref(false)
    const subtitle = ref(props.subtitle || "Escolha uma opção para filtrar")
    const routeNameToRedirect = ref<string | undefined>(props.routeNameToRedirect)
    const goToBackBtnDesc = ref<string | undefined>(props.goToBackBtnDesc)
    const showGoToBack = ref(props.routeNameToRedirect && props.goToBackBtnDesc ? true : false)
    const authStore = useAuthStore()
    // Company
    const companyStore = useCompanyStore()
    const companyId = ref<number | string>("")
    const companyRequired = ref(false)
    const companyRequiredCheck = ref(false)
    const companyShow = ref(false)
    const companies = ref<TOptionList>(null)
    // Store
    const storeStore = useStoreStore()
    const storeId = ref<number | string>("")
    const storeRequired = ref(false)
    const storeRequiredCheck = ref(false)
    const storeShow = ref(false)
    const stores = ref<TOptionList>(null)
    // Remittance
    const remittanceStore = useRemittanceStore()
    const remittanceRequired = ref(false)
    const remittanceRequiredCheck = ref(false)
    const remittanceShow = ref(false)
    const remittanceDateRange = ref<string[] | string>('')
    const remittanceCurrentDate = new Date();
    const remittanceMaxDate = new Date(remittanceCurrentDate.getFullYear(), remittanceCurrentDate.getMonth(), remittanceCurrentDate.getDate());
    // Sale
    const saleStore = useSaleStore()
    const saleRequired = ref(false)
    const saleRequiredCheck = ref(false)
    const saleShow = ref(false)
    const saleDateRange = ref<string[] | string>('')
    const saleCurrentDate = new Date();
    const saleMaxDate = new Date(saleCurrentDate.getFullYear(), saleCurrentDate.getMonth(), saleCurrentDate.getDate());
    // Post
    const postStore = usePostStore()
    const postRequired = ref(false)
    const postRequiredCheck = ref(false)
    const postShow = ref(false)
    const postDateRange = ref<string[] | string>('')
    const postCurrentDate = new Date();
    const postMaxDate = new Date(remittanceCurrentDate.getFullYear(), remittanceCurrentDate.getMonth(), remittanceCurrentDate.getDate());
    const countFilter = ref(0)
    const disabledSearch = ref(false)

    // Functions
    /* Defining form fields */
    //OBS: função utilizada para montar o formulario conforme cada pagina
    async function setFormFields() {
      // Company
      if(props.fields?.some((item) => item === "company"))
        companyShow.value = true
      if(props.requiredFields?.some((item) => item === "company"))
        companyRequired.value = true

      // Store
      if(props.fields?.some((item) => item === "store"))
        storeShow.value = true
      if(props.requiredFields?.some((item) => item === "store"))
        storeRequired.value = true

      // Remittance
      if(props.fields?.some((item) => item === "remittance"))
        remittanceShow.value = true
      if(props.requiredFields?.some((item) => item === "remittance"))
        remittanceRequired.value = true

      // Sale
      if(props.fields?.some((item) => item === "sale"))
        saleShow.value = true
      if(props.requiredFields?.some((item) => item === "sale"))
        saleRequired.value = true
      
      // Post
      if(props.fields?.some((item) => item === "post"))
        postShow.value = true
      if(props.requiredFields?.some((item) => item === "post"))
        postRequired.value = true

      await getLists()
      remittanceDateRange.value = remittanceStore.getDateRange
      postDateRange.value = postStore.getDateRange
    }

    /* Fetching data */
    // Função para popular select
    async function getLists() {
      try {
        isLoading.value = true;
        const promises: Promise<AxiosResponse<any>>[] = [];

        if (companyShow.value)
          promises.push(axios.get("/api/getCompanyList"));

        if (storeShow.value)
          promises.push(axios.get("/api/getStore"));

        const responses = await Promise.all(promises);

        const companyOptionsList: TOptionList =
          (responses.find((el) => el.config.url === "/api/getCompanyList"))?.
          data.data.map((el: TCompanyData) => ({ id: el.id, label: el.fantasy_name })) || null

        const storeOptionsList: TOptionList =
          (responses.find((el) => el.config.url === "/api/getStore"))?.
          data.data.map((el: IStoreData) => ({ id: el.id, label: el.fantasy_name, cnpj: el.cnpj })) || null

        setFieldList(companyOptionsList, storeOptionsList);
      } catch (error) {
        if (error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error");
          } else {
          showTimeAlert("Algo deu errado!", "error");
        }
      } finally {
        isLoading.value = false;
      }
    }

    /* Defining lists */
    function setFieldList(companyList: TOptionList, storeList: TOptionList) {
      // Company
      if(Array.isArray(companyList) && companyList?.length) {
        companies.value = companyRequired.value ?
         companyList 
         : 
          [...companyList] 
        companyId.value = companyStore.getId ? companyStore.getId : ""
      }
      // Store
      if(Array.isArray(storeList) && storeList?.length) {
        stores.value = storeRequired.value ? storeList : [...storeList]
        storeId.value = storeStore.getId ? storeStore.getId : ""
      }
    }

    function modalActivation() {
      if(filterMainStore.isModalActive)
        activeModal.value = filterMainStore.isModalActive
    }

    async function resetStoreField() {
      await storeStore.setId(0)
      getLists()
    }

    /* Clear warning */
    function clearWarning() {
      // Company
      if(storeId.value)
        storeRequiredCheck.value = false
      // Store
      if(companyId.value)
        companyRequiredCheck.value = false
      // Remittance
      if(remittanceDateRange.value)
        remittanceRequiredCheck.value = false
      // Sale
      if(saleDateRange.value)
        saleRequiredCheck.value = false
      // Post
      if(postDateRange.value)
        postRequiredCheck.value = false
      isWarning.value = false
    }

    /* Remittance - Max Date */
    function disabledDateInRemittance(time: Date) {
      return time.getTime() > remittanceMaxDate.getTime();
    }

    /* Sale - Max Date */
    function disabledDateInSale(time: Date) {
      return time.getTime() > saleMaxDate.getTime();
    }

    // Life Cycles / User Actions
    onMounted(() => setFormFields())

    watch(() => filterMainStore.isModalActive, () => modalActivation(), { deep: true })

    watch(() => { companyId.value, storeId.value, remittanceDateRange.value, postDateRange.value }, () => clearWarning(), { deep: true })

    watch(() => companyStore.id, () => companyId.value = companyStore.getId as number )

    watch(() => companyId.value, async() => {
      isLoading.value = true;
        const responses = await axios.get(`/api/getStore?id_company=${companyId.value}`);
        
        const storeOptionsList: TOptionList = 
        responses.data.data.map((el: IStoreData) => ({ id: el.id, label: el.fantasy_name, cnpj: el.cnpj })) || null
        if(Array.isArray(storeOptionsList) && storeOptionsList?.length) {
          stores.value = storeOptionsList
          storeId.value = storeStore.getId && !countFilter.value ? storeStore.getId : 0
        }
        countFilter.value++
        isLoading.value = false;
    })

    // watch(() => companyId.value, () => getLists())

    // watch(() => companyId.value, resetStoreField) /** Clear store field when changing company */

    // watch(() => storeId.value, () => handleGlobalStateToAutoSearch(true) )

    


    async function handleGlobalStateToAutoSearch(endModal: boolean = true) {
      disabledSearch.value = true
      // Company
      if(companyRequired.value && !companyId.value)
        companyRequiredCheck.value = true

      // Store
      if(storeRequired.value && !storeId.value)
        storeRequiredCheck.value = true

      // Remittance
      if(remittanceRequired.value && !remittanceDateRange.value)
        remittanceRequiredCheck.value = true

      // Sale
      if(saleRequired.value && !saleDateRange.value)
        saleRequiredCheck.value = true

      // Post
      if(postRequired.value && !postDateRange.value)
        postRequiredCheck.value = true

      // Stop flow - Required values
      if(companyRequiredCheck.value || storeRequiredCheck.value || remittanceRequiredCheck.value || postRequiredCheck.value)
        return isWarning.value = true

      // Company
      await companyStore.setId(typeof companyId.value === "string" ? null : companyId.value)

      // Store
      await storeStore.setId(typeof storeId.value === "string" ? null : storeId.value)
      storeStore.setFantasyName(stores.value ? stores.value.find(el => el.id === storeId.value)?.label || "" : "")
      await companyStore.setCompanyAndStore(typeof companyId.value === "string" ? null : companyId.value, typeof storeId.value === "string" ? null : storeId.value)
      // Remittance
      remittanceStore.setDateRange(remittanceDateRange.value)

      // Sale
      saleStore.setDateRange(saleDateRange.value)

      // Post
      postStore.setDateRange(postDateRange.value)
      disabledSearch.value = false
      // Close Modal
      if(endModal){
        if(filterMainStore.isModalActive)
        filterMainStore.hideModal()
        closeModal.value = !closeModal.value
      }
    }

    function handleDisabledSearch () {
      if(disabledSearch.value) {
        return true
      }
      if(companyShow.value) {
        if(companyId.value === '' || companyId.value === undefined) {
          return true
        }
      }
      if(storeShow.value) {
        if(storeId.value === '' || storeId.value === undefined) {
          return true
        }
      }
      return false
    }

    return {
      props,
      isWarning,
      activeModal,
      isLoading,
      closeModal,
      subtitle,
      routeNameToRedirect,
      goToBackBtnDesc,
      showGoToBack,
      handleGlobalStateToAutoSearch,
      // Company
      companies,
      companyId,
      companyRequiredCheck,
      companyShow,
      companyStore,
      // Store
      stores,
      storeId,
      storeShow,
      storeRequiredCheck,
      // Remittance - Date
      ptBr,
      remittanceDateRange,
      remittanceShow,
      remittanceRequiredCheck,
      disabledDateInRemittance,
      // Sale - Date
      saleDateRange,
      saleShow,
      saleRequiredCheck,
      disabledDateInSale,
      // Post - Date
      postShow,
      postRequiredCheck,
      postDateRange,
      // Auth
      authStore,
      disabledSearch,
      handleDisabledSearch
    };
  },
});
